















































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, toCamelCase, showError, toSnakeCase, constants } from 'utils'
import { Body, SizesBySizeGroup, ObjectWrapper, Size, SizeGroup, BottomSheetButton } from 'typings'
import { SizeDialog, SizeGroupDialog, MasterItemGroupComponent } from 'components'

interface SetUp {
  sizesBySizeGroup: Ref<SizesBySizeGroup[]>
  searchedSizes: Ref<SizesBySizeGroup[]>
  showSizeDialog: Ref<boolean>
  isAddSize: Ref<boolean>
  readonly: Ref<boolean>
  sizeProp: Ref<ObjectWrapper>
  openSizeDialog: (param: Size | number) => void
  saveSizeOrderInSizeGroup: (body: Body) => void
  showSizeGroupDialog: Ref<boolean>
  isAddSizeGroup: Ref<boolean>
  sizeGroupProp: Ref<ObjectWrapper>
  openSizeGroupDialog: (sizeGroup: SizeGroup | null) => void
  saveSizeGroupOrder: (body: Body) => void
  getSizesGroupedBySizeGroup: () => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  bottomSheetListButton: Array<BottomSheetButton>
}

const Sizes = defineComponent({
  components: {
    SizeDialog,
    SizeGroupDialog,
    MasterItemGroupComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const sizesBySizeGroup: Ref<SizesBySizeGroup[]> = ref([])
    const searchedSizes: Ref<SizesBySizeGroup[]> = ref([])
    const isSorting = ref(false)
    const readonly = ref(false)

    const sizeProp: Ref<ObjectWrapper> = ref({})
    const showSizeDialog = ref(false)
    const isAddSize = ref(true)

    const sizeGroupProp: Ref<ObjectWrapper> = ref({})
    const showSizeGroupDialog = ref(false)
    const isAddSizeGroup = ref(true)

    const { $store, $toast } = root
    const loading = ref(false)

    // list button to bottom sheet panel
    const bottomSheetListButton: Array<BottomSheetButton> = [
      {
        id: 1,
        text: root.$t('master.size_group.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add-group',
        disabled: false
      },
      {
        id: 2,
        text: root.$t('master.size.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add',
        disabled: false
      },
      {
        id: 3,
        text: root.$t('master.size_group.sort_size_groups') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort-group',
        disabled: false
      },
      {
        id: 4,
        text: root.$t('master.size.sort_sizes_in_1_group') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort',
        disabled: false
      },
      {
        id: 5,
        text: root.$t('common.cancel') as string,
        classes: ['red--text'],
        icon: '',
        action: 'on-close',
        disabled: false
      }
    ]

    const getSizesGroupedBySizeGroup = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await api.get(`${endpoints.SIZES}grouped_by_size_group`)
        // need to convert result from snake_case to camelCase
        sizesBySizeGroup.value = toCamelCase(
          data.map((group: SizesBySizeGroup) => {
            return { ...group, active: false }
          })
        )
        searchedSizes.value = [...sizesBySizeGroup.value]
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const saveSizeOrderInSizeGroup = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.SIZES}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getSizesGroupedBySizeGroup()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const saveSizeGroupOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.SIZE_GROUPS}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getSizesGroupedBySizeGroup()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    /*
     * param: could be object type or number
     * object type (for edit size)
     * number type (for create size) is SizeGroup's id, will be set to Size Group field
     * */
    const openSizeDialog = (param: Size | number): void => {
      if (typeof param === 'object') {
        sizeProp.value = JSON.parse(JSON.stringify(param))
        isAddSize.value = false
      } else {
        sizeProp.value = {
          id: 0,
          name: '',
          nameEng: null,
          orderNum: null,
          searchStr: null,
          shortName: null,
          yomi: null,
          // id of size group to set
          // 1 is the id of Default group
          sizeGroup: param || 1
        }
        isAddSize.value = true
      }
      showSizeDialog.value = true
    }

    const openSizeGroupDialog = (sizeGroup: SizeGroup | null): void => {
      if (sizeGroup) {
        sizeGroupProp.value = JSON.parse(JSON.stringify(sizeGroup))
        isAddSizeGroup.value = false
      } else {
        sizeGroupProp.value = {
          id: 0,
          isDefault: false,
          name: '',
          nameEng: null,
          orderNum: null,
          searchStr: null,
          shortName: null,
          yomi: null
        }
        isAddSizeGroup.value = true
      }
      showSizeGroupDialog.value = true
    }

    const setReadonlyFields = async () => {
      const userInfo = await $store.getters.getUserInfo
      readonly.value = userInfo.memberRole === constants.memberRole.ROLE_CHILD_GROWER
    }

    onMounted(() => {
      getSizesGroupedBySizeGroup()
      setReadonlyFields()
    })

    return {
      sizesBySizeGroup,
      sizeProp,
      showSizeDialog,
      isAddSize,
      sizeGroupProp,
      showSizeGroupDialog,
      isAddSizeGroup,
      isSorting,
      loading,
      getSizesGroupedBySizeGroup,
      openSizeDialog,
      saveSizeOrderInSizeGroup,
      saveSizeGroupOrder,
      openSizeGroupDialog,
      searchedSizes,
      bottomSheetListButton,
      readonly
    }
  }
})

export default Sizes
